import * as React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import "./App.css";
import Home from "./pages/Home/Home";
import Shops from "./pages/Shops/Shops";
import Offers from "./pages/Offers/Offers";
import Login from "./pages/Login/Login";
import Signup from "./pages/Signup/Signup";
import ShopDetails from "./pages/Shops/Details/Details";
import BookingPage from "./pages/Booking/History/History";
import ServicesShopsList from "./pages/Services/List/List";
import EditProfile from "./pages/EditProfile/EditProfile";

function App() {
  return (
    <React.Fragment>
      <div className="font-Mulish">
        <Router>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="shops" element={<Shops />} />
            <Route path="offers" element={<Offers />} />
            <Route path="booking-history" element={<BookingPage />} />
            <Route path="/edit-profile" element={<EditProfile />} />
            <Route path="login" element={<Login />} />
            <Route path="signup" element={<Signup />} />
            <Route path="shop/:id" element={<ShopDetails />} />
            <Route path="service-shops/:name" element={<ServicesShopsList />} />
          </Routes>
          <Toaster position="top-center" reverseOrder={false} />
          <Footer />
        </Router>
      </div>
    </React.Fragment>
  );
}

export default App;
