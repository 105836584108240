import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useFormik } from "formik";
import './Login.scss';
import AuthService from '../../services/AuthService';

const Login = (props) => {


    const validate = values => {
        let errors = {};


        if (!values.phone) {
            errors.phone = 'Required';
        } else if (values.phone.toString().length !== 10) {
            errors.phone = 'Phone number should be 10 digits';
        }

        if (!values.password) {
            errors.password = 'Required'
        } else if (values.password.length < 8) {
            errors.password = ' Please enter at least 8 characters';
        }

        return errors;
    }

    const validateNumber = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            formik.setFieldValue('phone', e.target.value);
        }
    }


    const formik = useFormik({
        initialValues: {
            phone: "",
            password: "",
        },
        onSubmit,
        validate,
        enableReinitialize: true,
    });


    const onSubmit = (values) => {

        let data = {
            mobileNumber: values.phone,
            password: values.password
        }

        AuthService.login(data).then((response) => {
            console.log('response', response);
        }, errors => {
            console.log('errors ', errors);
        })

    }


    return (
        <div>
            <Modal
                {...props}
                size="md"
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        let's sign you in
                    </Modal.Title>
                    <p>For Exciting Discounts & Cashback</p>
                </Modal.Header>
                <Modal.Body className='w-100'>
                    <form onSubmit={formik.handleSubmit}>
                        <div className='mt-3'>
                            <input type='number'
                                name='phone'
                                placeholder='Mobile Number'
                                className='form-control shadow-none'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.phone}
                            />
                            {formik.touched.phone && formik.errors.phone ? <div className="mt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">* {formik.errors.phone}</div> : null}
                        </div>
                        <div className='mt-3'>
                            <input type='password'
                                name='password'
                                placeholder='Password'
                                className='form-control shadow-none'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.password} />
                            {formik.touched.password && formik.errors.password ? (
                                <div className="mt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                                    * {formik.errors.password}
                                </div>
                            ) : null}
                        </div>
                        <div className='mt-3'>
                            <button type='submit' className='btn btn-primary'>Login</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    )

}

export default Login;