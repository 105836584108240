import React, { useState, useEffect } from "react";

import { Button, Modal } from "react-bootstrap";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import "../../assets/css/datetime.css";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import LoginModal from "./LoginModal";
import HomeService from "../../services/HomeService";
import "./BookingModal.css";

import { IoClose } from "react-icons/io5";

const BookingModal = (props) => {
  const [selectedDate, setSelectedDate] = useState();

  const [modalShow, setModalShow] = useState(false);

  const minDate = new Date();

  useEffect(() => {}, []);

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [day, month, year].join("/");
  };

  const changeDate = (e) => {
    setSelectedDate(e.target.value);
  };

  const bookAppointment = () => {
    if (localStorage.getItem("token") != null) {
      let data = {
        serviceName: props.data.serviceName,
        originalPrice: props.data.originalPrice,
        discountPrice: props.data.discountPrice,
        appointmentDate: formatDate(selectedDate),
        shopId: props.data.shopId,
      };

      HomeService.bookAppointment(data).then(
        (response) => {
          if (response.status == 200) {
            toast.success("Appointment requested successfully!");
          }
        },
        (errors) => {
          console.log("errors", errors);
        }
      );
    } else {
      setModalShow(true);
    }
  };

  return (
    <div>
      {props?.data && (
        <Modal {...props} centered>
          <Modal.Header className="border-0 flex justify-between items-center px-3 py-2">
            <h5 className="text-lg font-semibold pt-3">Details:</h5>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
              onClick={props.onHide}
            >
              <IoClose className="h-8 w-8 p-1 bg-gray-200 rounded-full text-black" />
            </button>
          </Modal.Header>
          <Modal.Body className="px-3 py-2 md:px-6 lg:px-8">
            <div className="mb-2 relative">
              <DatePickerComponent
                format="dd-MM-yyyy"
                min={minDate}
                value={selectedDate}
                onChange={changeDate}
                placeholder="DD/MM/YYYY"
                className="w-full"
                cssClass="custom-datepicker" // Apply the custom CSS class
              />
            </div>
            <div className="flex flex-col md:flex-row md:justify-between md:items-center mb-4">
              <div>
                <h4 className="text-lg font-bold text-gray-800">
                  {props.data.serviceName}
                </h4>
                <div className="flex-col text-right">
                  <div className="text-lg font-semibold text-green-600">
                    ₹{props.data.discountPrice}
                  </div>
                  <div className="text-sm text-gray-400 line-through">
                    ₹{props.data.originalPrice}
                  </div>
                </div>
              </div>
            </div>
            <button
              onClick={bookAppointment}
              className="w-full bg-blue-600 text-white font-semibold text-center py-2 rounded-md"
            >
              Book Appointment
            </button>
          </Modal.Body>
        </Modal>
      )}
      <LoginModal show={modalShow} onHide={() => setModalShow(false)} />
    </div>
  );
};

export default BookingModal;
