import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import BookingModal from "../../../components/Modal/BookingModal";
import "./Details.scss";
import HomeService from "../../../services/HomeService";
import HeroSearchBar from "../../../components/Search/HeroSearchBar";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";



import offer1 from "../../../assets/offers/offer1.jpg";
import offer2 from "../../../assets/offers/offer2.jpg";
import offer3 from "../../../assets/offers/offer3.jpg";

import "react-multi-carousel/lib/styles.css";

const ShopDetails = (props) => {
  let params = useParams();
  let deviceType = "desktop";

  const [modalShow, setModalShow] = useState(false);

  const [shopDetail, setShopDetail] = useState();

  const [serviceDetail, setServiceDetail] = useState();

  // Offer images from local storage
  const offers = [offer1, offer2, offer3];

  const [currentDay, setCurrentDay] = useState(null);

  // Dummy working hours data
  const workingHours = [
    { day: "Sunday", hours: "07:00 AM - 10:00 PM" },
    { day: "Monday", hours: "07:00 AM - 10:00 PM" },
    { day: "Tuesday", hours: "07:00 AM - 10:00 PM" },
    { day: "Wednesday", hours: "07:00 AM - 10:00 PM" },
    { day: "Thursday", hours: "07:00 AM - 10:00 PM" },
    { day: "Friday", hours: "07:00 AM - 10:00 PM" },
    { day: "Saturday", hours: "07:00 AM - 10:00 PM" },
  ];

  const dummyData = {
    shopName: "G3 Luxury Salon",
    shopType: "Uni-Sex", // Uni-Sex, Male, Female
    services: [
      { id: 1, serviceName: "Haircut", discountPrice: 200, originalPrice: 300 },
      {
        id: 2,
        serviceName: "Fire Cut",
        discountPrice: 250,
        originalPrice: 350,
      },
      {
        id: 3,
        serviceName: "Beard Shaping",
        discountPrice: 100,
        originalPrice: 150,
      },
      { id: 4, serviceName: "Facial", discountPrice: 500, originalPrice: 600 },
    ],
    rating: 4.0,
    reviews: 20,
    street: "Vilankurichi Road",
    area: "Saravanampatti",
    city: "Coimbatore",
    state: "Tamil Nadu",
    pincode: "641035",
    landmark: "Opp IOB Bank",
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  useEffect(() => {
    const today = new Date();
    // Adjust to IST if necessary
    const istTime = today.toLocaleString("en-US", { timeZone: "Asia/Kolkata" });
    const istDate = new Date(istTime);
    const dayIndex = istDate.getDay(); // This gives us the current day index
    setCurrentDay(dayIndex);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (params?.id) {
      getShopDetails(params?.id);
    } else {
      setShopDetail(dummyData);
    }
  }, [params]);

  const openMap = () => {
    console.log("https://maps.google.com?q=" + shopDetail.latLong);
    window.open("https://maps.google.com?q=" + shopDetail.latLong);
  };

  const getShopDetails = (id) => {
    HomeService.GetShopDetails(id)
      .then((response) => {
        try {
          // Parse images and videos
          const data = response?.data?.shopDetail || {};

          if (data.images) {
            data.images = JSON.parse(data.images);
          }

          data.images.push("https://picsum.photos/536/354");
          // add sample video
          data.images.push(
            "https://sample-videos.com/video321/mp4/720/big_buck_bunny_720p_1mb.mp4"
          );

          console.log(data.images);

          // Set fallback for missing data
          setShopDetail({
            shopName: data.shopName || dummyData.shopName,
            shopType: data.shopType || dummyData.shopType,
            services:
              data.services?.slice(0, 4) || dummyData.services.slice(0, 4), // Get up to 4 services
            rating: data.rating || dummyData.rating,
            reviews: data.reviews || dummyData.reviews,
            street: data.street || dummyData.street,
            area: data.area || dummyData.area,
            city: data.city || dummyData.city,
            state: data.state || dummyData.state,
            pincode: data.pincode || dummyData.pincode,
            landmark: data.landmark || dummyData.landmark,
            images: data.images || [],
          });
        } catch (error) {
          console.error("Error parsing JSON data:", error);
          setShopDetail(dummyData);
        }
      })
      .catch((error) => {
        console.error("Error fetching shop details:", error);
        setShopDetail(dummyData); // Fallback to dummy data on error
      });
  };

  const ArticleCard = (data, index) => {
    console.log(data, index);
    if (data.image.includes(".mp4")) {
      return (
        <video
          className="h-full w-full object-cover"
          controls
          key={index}
          src={data.image}
          type="video/mp4"
          autoPlay
          loop
          muted
        />
      );
    } else {
      return <img className="h-full w-full object-cover" src={data.image} key={index} />;
    }
  };

  const CustomDot = ({ shopDetail, onClick, active, index }) => {
    const image = shopDetail.images[index];
  
    return (
      <button
        onClick={(e) => {
          onClick();
          e.preventDefault();
        }}
        className={classNames("custom-dot", {
          "custom-dot--active": active,
        })}
        key={index}
      >
        {image.includes(".mp4") ? (
          <video
            className="h-12 w-12 object-cover"
            src={image}
            muted
            playsInline
            preload="metadata" // This ensures the video metadata (including the first frame) is loaded
          />
        ) : (
          <img
            className="h-12 w-12 object-cover"
            src={image}
            alt="Thumbnail"
          />
        )}
      </button>
    );
  };

  // const images = images.map((image, index) => <img key={index} src={image} />);

  return (
    <div>
      <HeroSearchBar />
      {shopDetail && (
        <div className="">
          <div className="border-b-2 border-gray-200 w-full"></div>
          <div className="relative w-full pt-0 shadow-md">
            <Carousel
              showDots
              deviceType={deviceType}
              // ssr
              slidesToSlide={1}
              containerClass="carousel-with-custom-dots"
              responsive={responsive}
              partialVisible
              infinite
              customDot={<CustomDot shopDetail={shopDetail} />}
            >
              {shopDetail?.images?.map((image, index) => {
                return <ArticleCard key={index} image={image} />;
              })}
            </Carousel>
          </div>

          <div className="mx-3 my-3 flex flex-col">
            <div className="flex flex-col">
              {/* Shop Name and Category */}
              <h1 className="text-2xl text-[#2A2A2A] font-extrabold flex items-center">
                {shopDetail?.shopName}
                <span
                  className={`ml-2 px-2 py-1 rounded-md text-xs font-bold ${
                    shopDetail.shopType === "Uni-Sex"
                      ? "!border border-[#8457f6] bg-clip-text text-transparent"
                      : shopDetail.shopType === "Male"
                      ? "!border !border-blue-500 text-blue-500"
                      : shopDetail.shopType === "Female"
                      ? "!border !border-pink-500 text-pink-500"
                      : "bg-gray-200 text-gray-500"
                  }`}
                  style={
                    shopDetail.shopType === "Uni-Sex"
                      ? {
                          background:
                            "linear-gradient(180deg, #0057FF 0%, #FF57EE 100%)",
                          WebkitBackgroundClip: "text",
                          WebkitTextFillColor: "transparent",
                        }
                      : {}
                  }
                >
                  {shopDetail?.shopType}
                </span>
              </h1>

              {/* Services */}
              <div className="text-gray-600 mt-1">
                {shopDetail.services
                  .map((service) => service.serviceName)
                  .join(", ")}
              </div>

              {/* Rating and Reviews */}
              <div className="flex flex-row items-center mt-2 pb-[17px]">
                <StarRateRoundedIcon style={{ color: "#FFB800" }} />
                <span className="text-[#2A2A2A] pl-1">
                  {shopDetail?.rating}
                </span>
                <span className="text-[#6B6B6B] pl-1">
                  ({shopDetail?.reviews} Reviews)
                </span>
              </div>
            </div>
            <div className="border-b-2 border-gray-200 w-full"></div>

            {/* Working Hours Section */}
            <div className="mt-4">
              <h4 className="text-[#2A2A2A] font-extrabold text-xl">
                Working Hours
              </h4>
              <ul className="mt-2 p-0">
                {workingHours.map((day, index) => (
                  <li
                    key={index}
                    className={`flex justify-between py-1 ${
                      index === currentDay
                        ? "font-bold text-black"
                        : "text-gray-600"
                    }`}
                  >
                    <span>{day.day}</span>
                    <span>{day.hours}</span>
                  </li>
                ))}
              </ul>
            </div>
            <div className="border-b-2 border-gray-200 w-full"></div>

            <div className="my-3 mt-2">
              <div className="mx-0 mt-3">
                <h5 className="font-extrabold text-md">Location</h5>
                <div className="font-semibold text-[#6B6B6B]">
                  <h6>
                    {shopDetail?.street}, {shopDetail?.area}, {shopDetail?.city}
                  </h6>
                  <h6>
                    {shopDetail?.state} - {shopDetail?.pincode}
                  </h6>
                  <h6>Landmark: {shopDetail?.landmark}</h6>
                </div>
                <button
                  onClick={openMap}
                  className="rounded-lg mt-1 w-full text-white bg-[#1E85FF] p-2 text-lg"
                >
                  View Map →
                </button>
              </div>
            </div>
            <div className="border-b-2 border-gray-200 w-full"></div>

            {/* Offers & Discounts Section */}
            {offers && offers.length > 0 && (
              <div className="offers-discounts mt-4">
                <h2 className="text-lg md:text-xl lg:text-2xl font-bold mb-3">
                  Offers & Discounts
                </h2>
                <Carousel
                  responsive={responsive}
                  infinite
                  autoPlay
                  autoPlaySpeed={3000}
                  keyBoardControl
                  showDots
                >
                  {offers.map((offer, index) => (
                    <div
                      key={index}
                      className="relative w-full"
                      style={{ paddingBottom: "24.39%" }}
                    >
                      <img
                        src={offer}
                        alt={`Offer ${index + 1}`}
                        className="absolute top-0 left-0 w-full h-full object-cover"
                      />
                    </div>
                  ))}
                </Carousel>
              </div>
            )}
          </div>

          <div className="px-3 py-2 bg-[#F2F2F2]">
            <h4 className="text-[#2A2A2A] mt-3 font-extrabold text-xl">
              Our Services
            </h4>
            <div className="mt-0 justify-content-between">
              <div className="grid grid-cols-2 gap-3">
                {shopDetail?.services?.map((service, index) => {
                  return (
                    <div
                      key={index}
                      className="text-[#2A2A2A] bg-white px-3 py-3 rounded-2xl flex flex-col"
                    >
                      <span className="font-bold capitalize h-16">
                        {service?.serviceName}
                      </span>
                      <div className="flex flex-row justify-between mt-3">
                        <span className="text-[#9D9D9D] text-xl">Fare:</span>
                        <div className="flex flex-col items-end">
                          <span className="text-[#00BD13] font-bold text-xl">
                            ₹ {service?.discountPrice}
                          </span>
                          <span className="text-[#9D9D9D]">
                            {" "}
                            <del>₹ {service?.originalPrice}</del>
                          </span>
                        </div>
                      </div>
                      <button
                        className="rounded-lg mt-4 bg-[#438179] font-bold p-2 text-[#2A2A2A] text-lg bg-opacity-5 border-[#2A2A2A] border-opacity-20 border-2"
                        onClick={() => {
                          setModalShow(true);
                          setServiceDetail(service);
                        }}
                      >
                        Book
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
      <BookingModal
        data={serviceDetail}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </div>
  );
};

export default ShopDetails;
