import React, { useEffect, useState } from "react";
import { BiStar, BiLocationPlus } from "react-icons/bi";
import BookingModal from "../Modal/BookingModal.js";
import { Link, useNavigate } from "react-router-dom";
import "./service-shops.scss";

const ServiceShop = (props) => {
  let navigate = useNavigate();

  const [serviceDetail, setServiceDetail] = useState();
  const [modalShow, setModalShow] = useState(false);

  let image = "";
  let images;
  useEffect(() => {
    images = JSON.parse(props?.shop?.images);
    if (images != null) {
      image = images[0];
    }
  }, []);

  /*return (
    /*<div className="mx-2 p-4 rounded-lg shadow-lg bg-white">
      <div className="flex justify-between items-start">
        <div>
          <h1 className="text-lg font-bold text-green-600">
            ₹{props?.shop?.discountPrice || ""}
          </h1>
          <h3
            onClick={() => {
              navigate(`/shop/${props.shop?.shopId}`);
            }}
            className="text-md font-semibold text-[#4a42c3] cursor-pointer"
          >
            {props?.shop?.shopName || ""}
          </h3>
          {/* <div className='d-flex align-items-center'>
                        <BiStar className='star__icon__size' />
                        <BiStar className='star__icon__size' />
                        <BiStar className='star__icon__size' />
                        <BiStar className='star__icon__size' />
                        <BiStar className='star__icon__size' />
                        <h5 className='p-0 m-0 shop__reviews-text'>(0 Reviews)</h5>
                    </div> 
        </div>
        <div className="text-right">
          <small className="font-semibold text-gray-500">
            {props?.shop?.shopCategory || ""}
          </small>
        </div>
      </div>
      <hr className="my-2" />
      <div className="grid grid-cols-2 gap-4 items-center">
        <div className="text-xs">
          <p>
            {props?.shop?.street}, {props?.shop?.area}
            <br />
            <small className="font-semibold text-gray-500">
              Landmark:
            </small>{" "}
            {props?.shop?.landmark || ""}
          </p>
          <button
            onClick={() => {
              setModalShow(true);
              setServiceDetail(props?.shop);
            }}
            className="mt-2 text-white bg-blue-500 py-1 px-2 rounded-md text-xs"
          >
            Book
          </button>
        </div>
        {/* <Link className='text-decoration-none shops__list-card' to={'/detail'}>
                            <button className='border-0 mt-4 btn btn-sm btn-primary shop__get-appointment-btn'>View Shop</button>
                        </Link> 
        {/* <button className='border-0 btn btn-sm btn-primary' style={{ width: "100%" }} onClick={() => { setModalShow(true); setServiceDetail(props?.shop) }}>Book Appointment</button> 
        <div className="flex justify-end">
          <img
            className="w-24 h-24 rounded-md object-cover"
            src={
              props?.shop?.images
                ? JSON.parse(props?.shop?.images)[0]
                : "http://via.placeholder.com/150x150"
            }
            alt={props?.shop?.shopName || "Shop Image"}
          />
        </div>
      </div>

      <BookingModal
        data={serviceDetail}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </div>
  );
};*/

return (
  <div className="bg-white rounded-lg shadow-md border border-gray-200 p-4 mb-4">
  <div className="flex justify-between items-start">
    <div className="flex-grow">
      <div className="flex items-center">
        <h1 className="text-lg font-bold text-green-600">
          ₹{props?.shop?.discountPrice || ""}
        </h1>
        {props?.shop?.originalPrice && (
          <span className="text-gray-400 line-through ml-2">
            ₹{props?.shop?.originalPrice}
          </span>
        )}
      </div>
      <div className="flex items-center mt-1">
        <span
          className="text-base font-bold text-gray-900 cursor-pointer"
          onClick={() => {
            navigate(`/shop/${props.shop?.shopId}`);
          }}
        >
          {props?.shop?.shopName || " - "}
        </span>
        {props?.shop?.shopCategory ? (
          <span
            className={`ml-2 flex justify-center items-center p-1 text-xs font-bold rounded-md ${
              props?.shop?.shopCategory === "Uni-Sex"
                ? "!border border-[#8457f6] bg-clip-text text-transparent"
                : props?.shop?.shopCategory === "Men"
                ? "!border !border-blue-500 text-blue-500"
                : props?.shop?.shopCategory === "Women"
                ? "!border !border-pink-500 text-pink-500"
                : "bg-gray-200 text-gray-500"
            }`}
            style={
              props?.shop?.shopCategory === "Uni-Sex"
                ? {
                    background:
                      "linear-gradient(180deg, #0057FF 0%, #FF57EE 100%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }
                : {}
            }
          >
            {props?.shop?.shopCategory}
          </span>
        ) : null}
      </div>
    </div>
    <div className="flex-shrink-0">
      <img
        className="w-8 h-8 rounded-full object-cover"
        src={
          props?.shop?.images
            ? JSON.parse(props?.shop?.images)[0]
            : "http://via.placeholder.com/150x150"
        }
        alt={props?.shop?.shopName || "Shop Image"}
      />
    </div>
  </div>
  <hr className="my-2" />
  <div className="text-sm text-gray-700 mb-4">
    <p>{props?.shop?.street}, {props?.shop?.area}</p>
    <p>{props?.shop?.landmark || "No landmark available"}</p>
  </div>
  <div className="flex justify-start">
    <button className="text-blue-600 border border-blue-600 text-sm font-semibold py-2 px-4 rounded-lg mr-2">
      View Map
    </button>
    <button
      onClick={() => {
        setModalShow(true);
        setServiceDetail(props?.shop);
      }}
      className="text-white bg-blue-600 text-sm font-semibold py-2 px-4 rounded-lg"
    >
      Book
    </button>
  </div>

  <BookingModal
    data={serviceDetail}
    show={modalShow}
    onHide={() => setModalShow(false)}
  />
</div>
);
};

export default ServiceShop;
