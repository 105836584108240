import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import AuthService from "../../services/AuthService";
import toast from "react-hot-toast";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { IoClose } from "react-icons/io5";

import logo from "../../assets/groomsy-logo.png";

function SignupModal(props) {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const validate = (values) => {
    let errors = {};

    if (!values.phone) {
      errors.phone = "Required";
    } else if (values.phone.toString().length !== 10) {
      errors.phone = "Phone number should be 10 digits";
    }

    if (!values.password) {
      errors.password = "Required";
    } else if (values.password.length < 8) {
      errors.password = " Please enter at least 8 characters";
    }

    if (!values.gender) {
      errors.gender = "Please select your gender";
    }

    return errors;
  };

  const onSubmit = (values) => {
    let data = {
      mobileNumber: values.phone,
      password: values.password,
      age: values.age,
      gender: values.gender,
      userEmail: "",
      userName: values.userName,
    };

    AuthService.signup(data).then(
      (response) => {
        if (response.status === 200) {
          toast.success("Account created successfully!");
          signupFormik.resetForm();
          props.data("login");
        }
      },
      (errors) => {
        console.log("errors ", errors);
      }
    );
  };

  const signupFormik = useFormik({
    initialValues: {
      phone: "",
      password: "",
      age: "",
      gender: "",
      userEmail: "",
      userName: "",
    },
    onSubmit,
    validate,
    enableReinitialize: true,
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div className="flex flex-col items-center bg-white p-6 md:p-8 lg:p-10 rounded-lg shadow-lg w-full max-w-sm md:max-w-md lg:max-w-lg mx-auto">
      {/* Custom Close Button */}
      <button
        onClick={props.onHide}
        className="absolute top-4 right-4 text-gray-700 hover:text-gray-900 focus:outline-none"
      >
        <IoClose className="h-8 w-8 p-1 bg-gray-200 rounded-full" />
      </button>
      <img src={logo} alt="Groomsy Logo" className="w-[120px]  mb-6" />
      <h2 className="text-xl md:text-2xl font-bold text-gray-800">
        Create Account,
      </h2>
      <p className="text-gray-600 text-center mb-4">to get started now!</p>

      <form onSubmit={signupFormik.handleSubmit} className="w-full">
        <input
          type="text"
          name="userName"
          placeholder="Username"
          className="mt-2 w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none shadow-sm"
          onChange={signupFormik.handleChange}
          onBlur={signupFormik.handleBlur}
          value={signupFormik.values.userName}
        />
        {signupFormik.touched.userName && signupFormik.errors.userName ? (
          <div className="text-red-600 text-sm mt-1">
            * {signupFormik.errors.userName}
          </div>
        ) : null}

        <input
          type="number"
          name="age"
          placeholder="Enter Your Age"
          className="mt-2 w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none shadow-sm"
          onChange={signupFormik.handleChange}
          onBlur={signupFormik.handleBlur}
          value={signupFormik.values.age}
        />
        {signupFormik.touched.age && signupFormik.errors.age ? (
          <div className="text-red-600 text-sm mt-1">
            * {signupFormik.errors.age}
          </div>
        ) : null}

        {/* Gender Selection */}
        <div className="mt-[8px]">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Gender
          </label>
          <div className="flex space-x-4">
            <button
              type="button"
              className={`flex-1 py-2 border rounded-md ${
                signupFormik.values.gender === "Male"
                  ? "bg-[#1E85FF]/5 text-[#1E85FF]"
                  : "bg-[#FFFFFF] text-[#9D9D9D]"
              }`}
              onClick={() => signupFormik.setFieldValue("gender", "Male")}
            >
              Male
            </button>
            <button
              type="button"
              className={`flex-1 py-2 border rounded-md ${
                signupFormik.values.gender === "Female"
                  ? "bg-[#1E85FF]/5 text-[#1E85FF]"
                  : "bg-[#FFFFFF] text-[#9D9D9D]"
              }`}
              onClick={() => signupFormik.setFieldValue("gender", "Female")}
            >
              Female
            </button>
          </div>
          {signupFormik.touched.gender && signupFormik.errors.gender ? (
            <div className="text-red-600 text-sm mt-1">
              * {signupFormik.errors.gender}
            </div>
          ) : null}
        </div>

        <input
          name="phone"
          placeholder="Mobile Number"
          className="mt-2 w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none shadow-sm"
          onChange={signupFormik.handleChange}
          onBlur={signupFormik.handleBlur}
          value={signupFormik.values.phone}
        />
        {signupFormik.touched.phone && signupFormik.errors.phone ? (
          <div className="text-red-600 text-sm mt-1">
            * {signupFormik.errors.phone}
          </div>
        ) : null}

        <div className="relative mt-2">
          <input
            type={showPassword ? "text" : "password"} // Toggle input type
            name="password"
            placeholder="Password"
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none shadow-sm"
            onChange={signupFormik.handleChange}
            onBlur={signupFormik.handleBlur}
            value={signupFormik.values.password}
          />
          <div
            className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
            onClick={togglePasswordVisibility}
          >
            {showPassword ? (
              <FaEyeSlash className="text-gray-500" />
            ) : (
              <FaEye className="text-gray-500" />
            )}
          </div>
        </div>
        {signupFormik.touched.password && signupFormik.errors.password ? (
          <div className="text-red-600 text-sm mt-1">
            * {signupFormik.errors.password}
          </div>
        ) : null}

        <div className="relative mt-2">
          <input
            type={showConfirmPassword ? "text" : "password"} // Toggle input type
            name="confirmPassword"
            placeholder="Confirm Password"
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none shadow-sm"
            onChange={signupFormik.handleChange}
            onBlur={signupFormik.handleBlur}
            value={signupFormik.values.confirmPassword}
          />
          <div
            className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
            onClick={toggleConfirmPasswordVisibility}
          >
            {showConfirmPassword ? (
              <FaEyeSlash className="text-gray-500" />
            ) : (
              <FaEye className="text-gray-500" />
            )}
          </div>
        </div>
        {signupFormik.touched.confirmPassword &&
        signupFormik.errors.confirmPassword ? (
          <div className="text-red-600 text-sm mt-1">
            * {signupFormik.errors.confirmPassword}
          </div>
        ) : null}

        <button
          className="mt-4 w-full bg-blue-600 text-white py-2 rounded-md"
          type="submit"
        >
          SIGN UP
        </button>
      </form>

      <div className="mt-4 text-center text-sm">
        <p className="text-gray-500 inline-block">Already have an account?</p>
        <span
          className="ml-1 text-black underline cursor-pointer"
          onClick={() => {
            props.data("login");
          }}
        >
          Sign in
        </span>
      </div>
    </div>
  );
}

export default SignupModal;
