import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
/*import "./LoginModal.css";*/
import AuthService from "../../services/AuthService";
import SignupModal from "./SignupModal";
import toast from "react-hot-toast";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { IoClose } from "react-icons/io5";

import logo from "../../assets/groomsy-logo.png";

function LoginModal(props) {
  const [showModalContent, setShowModalContent] = useState("login");
  const [showPassword, setShowPassword] = useState(false); // State to manage password visibility
  const [loginError, setLoginError] = useState(""); // State to capture login errors

  const validate = (values) => {
    let errors = {};

    if (!values.phone) {
      errors.phone = "Required";
    } else if (values.phone.toString().length !== 10) {
      errors.phone = "Phone number should be 10 digits";
    }

    if (!values.password) {
      errors.password = "Required";
    } else if (values.password.length < 8) {
      errors.password = " Please enter at least 8 characters";
    }

    return errors;
  };

  const onSubmit = (values) => {
    let data = {
      mobileNumber: values.phone,
      password: values.password,
    };

    AuthService.login(data).then(
      (response) => {
        if (response.status === 200) {
          localStorage.setItem("token", response["data"].token);
          toast.success("Login success!");
          formik.resetForm();
          props.onHide();
          window.location.reload();
        }
      },
      (errors) => {
        console.log("errors ", errors);
        setLoginError("Incorrect mobile number or password.");
      }
    );
  };

  const formik = useFormik({
    initialValues: {
      phone: "",
      password: "",
    },
    onSubmit,
    validate,
    enableReinitialize: true,
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const showContent = (status) => {
    switch (status) {
      case "login":
        return (
          <div className="w-full bg-white p-8 rounded-lg shadow-lg relative">
            {/* Custom Close Button */}
            <button
              onClick={props.onHide}
              className="absolute top-4 right-4 text-gray-700 hover:text-gray-900 focus:outline-none"
            >
              <IoClose className="h-8 w-8 p-1 bg-gray-200 rounded-full" />
            </button>
            <div className="flex flex-col items-center">
              <img src={logo} alt="Groomsy Logo" className="w-32 mb-4" />
              <h2 className="text-2xl font-bold text-gray-800">Welcome,</h2>
              <p className="text-gray-600 text-center mb-6">Glad to see you!</p>

              <form onSubmit={formik.handleSubmit} className="w-full">
                <input
                  name="phone"
                  placeholder="Mobile Number"
                  className="mt-2 w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phone}
                />
                {formik.touched.phone && formik.errors.phone ? (
                  <div className="text-red-600 text-sm mt-1">
                    * {formik.errors.phone}
                  </div>
                ) : null}

                <div className="mt-4 w-full relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder="Password"
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                  />
                  <div
                    className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? (
                      <FaEyeSlash className="text-gray-500" />
                    ) : (
                      <FaEye className="text-gray-500" />
                    )}
                  </div>
                </div>
                {formik.touched.password && formik.errors.password ? (
                  <div className="text-red-600 text-sm mt-1">
                    * {formik.errors.password}
                  </div>
                ) : null}

                {loginError && (
                  <div className="text-red-600 text-sm mt-2">
                    * {loginError}
                  </div>
                )}

                <div className="mt-4 w-full flex justify-end text-sm">
                  <span
                    className="text-blue-600 cursor-pointer"
                    onClick={() => {
                      setShowModalContent("forgotPassword");
                    }}
                  >
                    Forgot Password?
                  </span>
                </div>

                <button
                  className="mt-4 w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  type="submit"
                >
                  SIGN IN
                </button>
              </form>

              <div className="mt-4 w-full flex justify-center text-sm">
                <p className="text-gray-500">Don't have an account?</p>
                <span
                  className="ml-2 text-black underline cursor-pointer"
                  onClick={() => {
                    setShowModalContent("signup");
                  }}
                >
                  Sign up
                </span>
              </div>
            </div>
          </div>
        );
      case "signup":
        return <SignupModal data={setShowModalContent} onHide={props.onHide}/>;
      case "forgotPassword":
        return (
          <div className="w-full bg-white p-8 rounded-lg shadow-lg relative">
            {/* Custom Close Button */}
            <button
              onClick={props.onHide}
              className="absolute top-4 right-4 text-gray-700 hover:text-gray-900 focus:outline-none"
            >
              <IoClose className="h-8 w-8 p-1 bg-gray-200 rounded-full" />
            </button>
            <h2 className="text-2xl font-bold text-gray-800 mb-4 text-center">
              Forgot Password
            </h2>
            <form className="w-full" autoComplete="off">
              <input
                className="w-full mb-4 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                type="text"
                maxLength="10"
                placeholder="Enter your email"
              />
              <button className="w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500">
                SEND LINK
              </button>
            </form>

            <div className="mt-4 w-full flex justify-center text-sm">
              <p className="text-gray-500">Remember your password?</p>
              <span
                className="ml-2 text-black underline cursor-pointer"
                onClick={() => {
                  setShowModalContent("login");
                }}
              >
                Back to Login
              </span>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Modal
      {...props}
      size="sm" // Keep the modal size small across all devices
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="border-0"
      contentClassName="p-0 m-0 border-0 mx-auto" // Ensure the modal is centered and has no added margins or padding
    >
      <Modal.Body className="p-0 flex justify-center items-center">
        {showContent(showModalContent)}
      </Modal.Body>
    </Modal>
  );
}

export default LoginModal;
