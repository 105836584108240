import React, { useEffect, useState } from "react";
import { BiStar, BiLocationPlus } from "react-icons/bi";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./shop-horizontal-card.scss";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";

const ShopHorizontalCard = (props) => {
  const [image, setImage] = useState("");
  const [shopDetails, setShopDetails] = useState({
    shopName: "G3 Luxury Salon",
    shopCategory: "Uni-Sex",
    rating: 4.0,
    reviews: 20,
    services: "Haircut, Fire Cut, Beard Shaping.",
    address:
      "Vilankurichi Road, Saravanampatti, Coimbatore, Landmark: Opp IOB Bank",
  });

  useEffect(() => {
    if (props?.shop) {
      const images = JSON.parse(props?.shop?.images);
      if (images && images.length > 0) {
        setImage(images[0]);
      }

      // Update shop details if data is coming from the backend
      setShopDetails({
        shopName: props.shop.shopName || "G3 Luxury Salon",
        shopCategory: props.shop.shopCategory || "Uni-Sex",
        rating: props.shop.rating || 4.0,
        reviews: props.shop.reviews || 20,
        services: props.shop.services || "Haircut, Fire Cut, Beard Shaping.",
        address:
          props.shop.address ||
          "Vilankurichi Road, Saravanampatti, Coimbatore, Landmark: Opp IOB Bank",
      });
    }
  }, [props.shop]);

  return (
    <div className="p-4 w-full">
      <div className="flex justify-between items-start">
        <div>
          <h2 className="text-lg font-bold min-h-[56px] text-gray-900">
            {shopDetails.shopName}
          </h2>
          <div className="flex items-center mt-1">
            <StarRateRoundedIcon style={{ color: "#FFB800" }} />
            <span className="text-gray-900 pl-1">
              {shopDetails.rating.toFixed(1)}
            </span>
            <span className="text-gray-500 pl-2 text-xs">
              ({shopDetails.reviews} Reviews)
            </span>
          </div>
        </div>
        {image ? (
          <img
            src={image}
            alt="Shop"
            className="w-16 h-16 object-cover rounded-md"
          />
        ) : (
          <div className="w-16 h-16 bg-gray-200 object-cover rounded-md"></div>
        )}
      </div>
      <div className="mt-3">
        <span
          className={`ml-2 flex justify-center items-center p-1 text-xs font-bold rounded-md ${
            shopDetails.shopCategory === "Uni-Sex"
              ? "!border border-[#8457f6] bg-clip-text text-transparent"
              : shopDetails.shopCategory === "Men"
              ? "!border !border-blue-500 text-blue-500"
              : shopDetails.shopCategory === "Women"
              ? "!border !border-pink-500 text-pink-500"
              : "bg-gray-200 text-gray-500"
          }`}
          style={
            shopDetails.shopCategory === "Uni-Sex"
              ? {
                  background:
                    "linear-gradient(180deg, #0057FF 0%, #FF57EE 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }
              : {}
          }
        >
          {shopDetails.shopCategory}
        </span>
      </div>
      <p className="text-gray-600 mt-2">{shopDetails.services}</p>
      <div className="mt-3 text-gray-500">
        <p className="text-sm">{shopDetails.address}</p>
      </div>
      <Link
        to="#"
        className="block mt-4 text-center text-blue-500 font-bold bg-blue-50 py-2 text-decoration-none rounded-lg border border-blue-200"
      >
        View Map →
      </Link>
    </div>
  );
};

export default ShopHorizontalCard;
