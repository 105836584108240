import React from "react";
import Carousel from "react-multi-carousel";
import "./Slider.scss";
import minusIcon from "../../assets/icons/minus.png";
import Slider1 from "../../assets/img/slider1.png";
import Slider2 from "../../assets/img/slider2.png";
import Slider3 from "../../assets/img/slider3.png";

import Postor1 from "../../assets/img/postor1.png";
import Postor2 from "../../assets/img/postor2.png";
import Postor3 from "../../assets/img/postor3.png";
import Postor4 from "../../assets/img/postor4.png";

const Slider = () => {
  return (
    <div className="">
      <div className=" container mx-auto px-4">
        <div
          id="carouselExampleIndicators"
          className="relative carousel slide mt-[16px]"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner relative w-328 overflow-hidden rounded-xl">
            <div className="carousel-item active float-left w-full">
              <img
                src={Slider1}
                className="block w-full h-[152px] object-cover md:h-[250px] lg:h-[300px]"
                alt=""
              />
            </div>
            <div className="carousel-item float-left w-full">
              <img
                src={Slider2}
                className="block w-full h-[152px] object-cover md:h-[250px] lg:h-[300px]"
                alt=""
              />
            </div>
            <div className="carousel-item float-left w-full">
              <img
                src={Slider3}
                className="block w-full h-[152px] object-cover md:h-[250px] lg:h-[300px]"
                alt=""
              />
            </div>
          </div>
          <button
            className="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon inline-block bg-no-repeat"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon inline-block bg-no-repeat"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>

        {/*Offer Zone*/}
        <section className="overflow-hidden text-gray-700 mt-4">
          <h2 className="text-center font-extrabold bg-clip-text text-[#495164] mb-4 text-[14px] leading-[17.57px] tracking-[0.04em] bg-[#495164] w-[296px] mx-auto md:text-[18px] md:leading-[21px] lg:text-[20px] lg:leading-[24px] md:w-[360px] lg:w-[400px]">
    OFFER ZONE
  </h2>
          <div className="container px-1 py-1 mx-auto lg:pt-12 lg:px-32">
            <div className="flex flex-wrap -m-1 md:-m-2">
              <div className="flex flex-wrap w-1/2">
                <div className="w-full p-1 md:p-2">
                  <img
                    alt=""
                    className="block object-cover object-center w-full h-full rounded-lg"
                    src={Postor1}
                  />
                </div>
              </div>
              <div className="flex flex-wrap w-1/2">
                <div className="w-full p-1 md:p-2">
                  <img
                    alt=""
                    className="block object-cover object-center w-full h-full rounded-lg"
                    src={Postor2}
                  />
                </div>
              </div>
              <div className="flex flex-wrap w-1/2">
                <div className="w-full p-1 md:p-2">
                  <img
                    alt=""
                    className="block object-cover object-center w-full h-full rounded-lg"
                    src={Postor3}
                  />
                </div>
              </div>
              <div className="flex flex-wrap w-1/2">
                <div className="w-full p-1 md:p-2">
                  <img
                    alt=""
                    className="block object-cover object-center w-full h-full rounded-lg"
                    src={Postor4}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Slider;
