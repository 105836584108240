import React from "react";
import Carousel from "react-multi-carousel";
import { Link } from "react-router-dom";
import ShopHorizontalCard from "../../components/shop-horizontal-card/shop-horizontal-card";
import "./Offers.scss";
import minusIcon from "../../assets/icons/minus.png";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 600, min: 300 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const CustomDot = ({ onMove, index, onClick, active }) => {
  return (
    <li className={active ? "active" : "inactive"} onClick={() => onClick()}>
      <img src={minusIcon} width={active ? "50px" : "25px"} height="30px" />
    </li>
  );
};

const Offers = () => {
  return (
    <div>
      <div className="container my-3 p-3 offers-container">
        <div className="d-flex justify-content-between">
          <h5 className="font-weight-bold">OFFERS</h5>
          <Link className="text-decoration-none" to="/Shops">
            <span className="view__all-btn">View All</span>
          </Link>
        </div>
        <hr className="home__hr-line" />
        <Carousel
          swipeable={true}
          draggable={false}
          showDots={false}
          responsive={responsive}
          ssr={false} // means to render carousel on server-side.
          infinite={true}
          // autoPlay={this.props.deviceType !== "mobile" ? true : false}
          autoPlaySpeed={1000}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile"]}
          // deviceType={this.props.deviceType}
          dotListClass="custom-dot-list-style"
          itemClass="my-3 "
          customDot={<CustomDot />}
        >
          <ShopHorizontalCard />
          <ShopHorizontalCard />
          <ShopHorizontalCard />
          <ShopHorizontalCard />
          <ShopHorizontalCard />
        </Carousel>
      </div>
    </div>
  );
};

export default Offers;
