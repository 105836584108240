import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const HeroSearchBar = () => {
  const [location, setLocation] = useState("");
  const [query, setQuery] = useState("");
  const [time, setTime] = useState("Anytime");
  const [isMoreOpen, setIsMoreOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const navigate = useNavigate();

  const [textIndex, setTextIndex] = useState(0);
  const texts = [
    "Book your next Manicure",
    "Book your next Haircut",
    "Book your next Facial",
    "Book your next MakeUp",
    "Book your next Massage",
    "Book your next HairColouring",
  ];

  useEffect(() => {
    // Check if the window size is greater than 640px (sm in Tailwind)
    if (window.innerWidth > 640) {
      const interval = setInterval(() => {
        setTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
      }, 5000); // Change text every 5 seconds

      return () => clearInterval(interval); // Clean up the interval on component unmount
    }
  }, [texts.length]);

  const handleSearch = () => {
    if (location && query) {
      navigate(`/search-results?location=${location}&query=${query}&time=${time}`);
    } else if (location) {
      navigate(`/search-results?location=${location}&time=${time}`);
    } else if (query) {
      navigate(`/search-results?query=${query}&time=${time}`);
    } else {
      navigate(`/search-results?time=${time}`);
    }
  };

  const handleServiceClick = (service) => {
    navigate(`/service-shops/${service}`);
  };

  const toggleMore = () => {
    setIsMoreOpen(!isMoreOpen);
  };

  const openSearch = () => {
    if (window.innerWidth <= 640) {  // Trigger only on mobile (sm and smaller)
      setIsSearchOpen(true);
    }
  };

  const closeSearch = () => {
    setIsSearchOpen(false);
  };

  return (
    <div
      className="bg-cover bg-center md:h-[280px] flex flex-col justify-center items-center shadow-md"
      style={{ backgroundImage: `url('/path-to-your-banner-image.jpg')` }}
    >
      <h1 className="text-black text-4xl pt-4 font-bold mb-6 hidden sm:block">
        {texts[textIndex]}
      </h1>

      {/* Desktop Mode Search Bar */}
      <div
        className="hidden sm:flex bg-black p-1 rounded-full items-center w-full max-w-5xl shadow-lg cursor-pointer"
      >
        <input
          type="text"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          placeholder="Business Name or Location"
          className="w-full sm:w-1/3 p-4 border border-gray-300 rounded-t-full sm:rounded-t-none sm:rounded-l-full focus:outline-none"
        />
        <input
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Search Services"
          className="w-full sm:w-1/3 p-4 border-t border-b border-gray-300 focus:outline-none"
        />
        <select
          value={time}
          onChange={(e) => setTime(e.target.value)}
          className="w-full sm:w-1/3 p-4 h-[74px] border-t border-b border-gray-300 focus:outline-none"
        >
          <option>Anytime</option>
          <option>Morning</option>
          <option>Afternoon</option>
          <option>Evening</option>
        </select>
        <button
          onClick={handleSearch}
          className="bg-white text-black px-8 py-4 h-[74px] rounded-b-full sm:rounded-b-none sm:rounded-r-full focus:outline-none"
        >
          Search
        </button>
      </div>

      <div className="hidden sm:flex space-x-4 mt-4">
        <button onClick={() => handleServiceClick('Haircut')} className="px-4 py-2 rounded-full bg-gray-200">Haircut</button>
        <button onClick={() => handleServiceClick('Facial')} className="px-4 py-2 rounded-full bg-gray-200">Facial</button>
        <button onClick={() => handleServiceClick('Massage')} className="px-4 py-2 rounded-full bg-gray-200">Massage</button>
        <button onClick={() => handleServiceClick('Pedicure')} className="px-4 py-2 rounded-full bg-gray-200">Pedicure</button>
        <button onClick={() => handleServiceClick('Shaving')} className="px-4 py-2 rounded-full bg-gray-200">Shaving</button>
        <button className="px-4 py-2 rounded-full bg-gray-200" onClick={toggleMore}>
          More...
        </button>
      </div>

      {/* More Services Modal */}
      {isMoreOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-8 rounded-lg max-w-lg w-full">
            <div className="flex justify-between">
              <h2 className="text-xl font-bold">More Services</h2>
              <button onClick={toggleMore} className="text-black text-xl">
                &times;
              </button>
            </div>
            <div className="mt-4">
              {/* Women Services */}
              <h3 className="text-lg font-semibold">Women</h3>
              <div className="flex flex-wrap gap-2 mb-6">
                <button onClick={() => handleServiceClick('Haircut')} className="px-4 py-2 rounded-full bg-gray-200">Haircut</button>
                <button onClick={() => handleServiceClick('HairColouring')} className="px-4 py-2 rounded-full bg-gray-200">HairColouring</button>
                <button onClick={() => handleServiceClick('Facial')} className="px-4 py-2 rounded-full bg-gray-200">Facial</button>
                <button onClick={() => handleServiceClick('Massage')} className="px-4 py-2 rounded-full bg-gray-200">Massage</button>
                <button onClick={() => handleServiceClick('Keratin')} className="px-4 py-2 rounded-full bg-gray-200">Keratin</button>
                <button onClick={() => handleServiceClick('Pedicure')} className="px-4 py-2 rounded-full bg-gray-200">Pedicure</button>
                <button onClick={() => handleServiceClick('HairSpa')} className="px-4 py-2 rounded-full bg-gray-200">Hair Spa</button>
                <button onClick={() => handleServiceClick('MakeUp')} className="px-4 py-2 rounded-full bg-gray-200">MakeUp</button>
                <button onClick={() => handleServiceClick('Manicure')} className="px-4 py-2 rounded-full bg-gray-200">Manicure</button>
                <button onClick={() => handleServiceClick('Straightening')} className="px-4 py-2 rounded-full bg-gray-200">Straightening</button>
                <button onClick={() => handleServiceClick('Wax')} className="px-4 py-2 rounded-full bg-gray-200">Wax</button>
              </div>
              {/* Men Services */}
              <h3 className="text-lg font-semibold">Men</h3>
              <div className="flex flex-wrap gap-2">
                <button onClick={() => handleServiceClick('Haircut')} className="px-4 py-2 rounded-full bg-gray-200">Haircut</button>
                <button onClick={() => handleServiceClick('Hair Colouring')} className="px-4 py-2 rounded-full bg-gray-200">Hair Colouring</button>
                <button onClick={() => handleServiceClick('Shaving')} className="px-4 py-2 rounded-full bg-gray-200">Shaving</button>
                <button onClick={() => handleServiceClick('Facial')} className="px-4 py-2 rounded-full bg-gray-200">Facial</button>
                <button onClick={() => handleServiceClick('Head Massage')} className="px-4 py-2 rounded-full bg-gray-200">Head Massage</button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Mobile Mode Search Bar */}
      <div className="bg-white w-[360px] h-[76px] p-[16px_16px_16px_16px] top-[56px] gap-[10px] shadow-[0px_1px_0px_0px_#EEEEEE] md:shadow-none border-t-2 md:border-none" >
      <div
        className="sm:hidden flex items-center w-[328px] h-[44px] bg-[#F2F2F2]  p-[12px_16px] border-[1px] border-[#DDDDDD] rounded-lg shadow-lg cursor-pointer justify-between"
        onClick={openSearch}
      >
        
        <input
          type="text"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          placeholder="Search"
          className="w-full h-[18px] p-2 bg-[#F2F2F2] border-none focus:outline-none font-[600] text-[14px] leading-[17.57px] text-left"
          style={{fontFamily: 'Mulish'}}
        />
        <svg
          className="w-5 h-5 text-gray-500 mr-2"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          ></path>
        </svg>
      </div>
      </div>

      {/* Full-screen Search Modal for Mobile */}
      {isSearchOpen && (
        <div className="fixed inset-0 bg-white z-50 p-4 sm:hidden"> {/* Hidden on sm and larger */}
          <div className="flex justify-between items-center">
            <button onClick={closeSearch} className="text-black text-xl">
              &larr;
            </button>
          </div>
          <div className="mt-4">
            <input
              type="text"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              placeholder="Business Name or Location"
              className="w-full p-4 border border-gray-300 rounded-lg mb-4"
            />
            <input
              type="text"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Search Services and Classes"
              className="w-full p-4 border border-gray-300 rounded-lg mb-4"
            />
            <input
              type="text"
              value={time}
              onChange={(e) => setTime(e.target.value)}
              placeholder="Anytime"
              className="w-full p-4 border border-gray-300 rounded-lg mb-4"
            />
            <button
              onClick={handleSearch}
              className="bg-black text-white w-full py-4 rounded-lg focus:outline-none"
            >
              Search
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default HeroSearchBar;
