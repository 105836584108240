import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import Shops from "../Shops/Shops";
import "react-multi-carousel/lib/styles.css";
import ShopHorizontalCard from "../../components/shop-horizontal-card/shop-horizontal-card";
import "./Home.scss";
import { Link, useNavigate } from "react-router-dom";
import minusIcon from "../../assets/icons/minus.png";
import WomenHaircutIcon from "../../assets/services/women_haircut.png";
import MenHaircutIcon from "../../assets/services/men_haircut.png";
import BeardIcon from "../../assets/services/shave.png";
import HairColorIcon from "../../assets/services/hair_colouring.png";
import MenHairColorIcon from "../../assets/services/men_haircoloring.png";
import Keratin from "../../assets/services/keratin.png";

import Makeup from "../../assets/services/makeup.png";
import Hairspa from "../../assets/services/hairspa.png";
import Manicure from "../../assets/services/manicure.png";
import Pedicure from "../../assets/services/pedicure.png";
import Straightening from "../../assets/services/straightening.png";
import Wax from "../../assets/services/wax.png";

import MassageIcon from "../../assets/services/massage.png";
import MassageMen from "../../assets/services/massagemen.png";
import Facial from "../../assets/services/facial.png";
import MenFacial from "../../assets/services/menfacial.png";
import Slider from "../../components/Slider/Slider";
import HomeService from "../../services/HomeService";
import HeroSearchBar from "../../components/Search/HeroSearchBar";
import { PrevArrow } from "../../components/arrows/PrevArrow";
import { NextArrow } from "../../components/arrows/NextArrow";
import HorizontalScrollProgressBar from "../../components/progress-bar/HorizontalScrollProgressBar";

import HomeSalon from "../../assets/img/home_salon.png";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1.14,
  },
};

const womenServicesResponsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 7,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 8,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3,
  },
};

const menServicesResponsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 7,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 8,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3,
  },
};

const Home = (props) => {
  const [shopsList, setShopsList] = useState([]);
  const [scrollProgress, setScrollProgress] = useState(0);
  const [womenScrollProgress, setWomenScrollProgress] = useState(0);
  const [menScrollProgress, setMenScrollProgress] = useState(0);

  let navigate = useNavigate();

  useEffect(() => {
    getShopsList();
  }, []);

  const getShopsList = () => {
    HomeService.GetShopsList().then((res) => {
      let data = res["data"];
      setShopsList(data.shops);
    });
  };

  const handleAfterChange = (currentSlide) => {
    const totalSlides = shopsList.length;
    console.log(totalSlides, currentSlide);

    // const progress = ((currentSlide + 1) / totalSlides) * 100;
    let progress = (100 / totalSlides) * (currentSlide + 1);

    if (currentSlide == 0) {
      progress = 1;
    }

    if (currentSlide + 1 == totalSlides) {
      progress = 100;
    }

    setScrollProgress(progress);
    console.log("Progress", progress);
  };

  const handleProgressBar = (currentSlide, totalItems, setProgress) => {
    const progress = ((currentSlide + 1) / totalItems) * 100;
    setProgress(progress);
  };

  return (
    <div>
      <HeroSearchBar />
      <Slider />

      <div className="container my-12">
        <h5 className="text-center font-extrabold tracking-wider pb-8">
          <span className="bg-clip-text bg-[#7c7c7c] text-[#495164] mr-2 font-extrabold text-[14px] leading-[17.57px] md:text-[18px] md:leading-[21px] lg:text-[20px] lg:leading-[24px]">
            Exclusive Services For
          </span>
          <span className="text-[#FF57EE] text-[14px] leading-[17.57px] md:text-[18px] md:leading-[21px] lg:text-[20px] lg:leading-[24px]">
            Women
          </span>
        </h5>
        <Carousel
          swipeable={true}
          draggable={false}
          showDots={false}
          responsive={womenServicesResponsive}
          ssr={false} // means to render carousel on server-side.
          infinite={false}
          autoPlaySpeed={1000}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["mobile"]}
          dotListClass="custom-dot-list-style"
          afterChange={(currentSlide) => {
            handleProgressBar(currentSlide, womenServicesResponsive.desktop.items, setWomenScrollProgress);
          }}

        >
          {/* Women's Services Items */}
          <div
            className="p-2 mx-2 text-center flex flex-column items-center justify-center"
            onClick={() => {
              navigate(`/service-shops/Haircut`);
            }}
          >
            <div className="h-[100px] w-[100px] shadow-md rounded-full flex justify-center items-center">
              <img src={WomenHaircutIcon} className="services__icon" style={{ width: '100px', height: '100px' }} />
            </div>
            <div className="mt-2 text-sm font-extrabold  bg-clip-text text-[#6B6B6B] text-center tracking-wide">
              <span>Haircut</span>
            </div>
          </div>

          <div
            className="p-2 mx-2 text-center flex flex-column items-center justify-center "
            onClick={() => {
              navigate(`/service-shops/Hair Coluring`);
            }}
          >
            <div className="h-[100px] w-[100px] shadow-md rounded-full flex justify-center items-center">
              <img src={HairColorIcon} className="services__icon" style={{ width: '100px', height: '100px' }} />
            </div>
            <div className="mt-2 text-sm font-extrabold  bg-clip-text text-[#6B6B6B] text-center tracking-wide">
              <span>Hair coloring</span>
            </div>
          </div>

          <div
            className="p-2 mx-2 text-center flex flex-column items-center justify-center "
            onClick={() => {
              navigate(`/service-shops/Hair Coluring`);
            }}
          >
            <div className="h-[100px] w-[100px] shadow-md rounded-full flex justify-center items-center">
              <img src={Facial} className="services__icon" style={{ width: '100px', height: '100px' }} />
            </div>
            <div className="mt-2 text-sm font-extrabold  bg-clip-text text-[#6B6B6B] text-center tracking-wide">
              <span>Facial</span>
            </div>
          </div>

          <div
            className="p-2 mx-2 text-center flex flex-column items-center justify-center "
            onClick={() => {
              navigate(`/service-shops/Head Massage`);
            }}
          >
            <div className="h-[100px] w-[100px] shadow-md rounded-full flex justify-center items-center">
              <img src={MassageIcon} className="services__icon" style={{ width: '100px', height: '100px' }} />
            </div>
            <div className="mt-2 text-sm font-extrabold  bg-clip-text text-[#6B6B6B] text-center tracking-wide">
              <span>Massage</span>
            </div>
          </div>
          <div
            className="p-2 mx-2 text-center flex flex-column items-center justify-center "
            onClick={() => {
              navigate(`/service-shops/Keratin`);
            }}
          >
            <div className="h-[100px] w-[100px] shadow-md rounded-full flex justify-center items-center">
              <img src={Keratin} className="services__icon" style={{ width: '100px', height: '100px' }} />
            </div>
            <div className="mt-2 text-sm font-extrabold  bg-clip-text text-[#6B6B6B] text-center tracking-wide">
              <span>Keratin</span>
            </div>
          </div>

          <div
            className="p-2 mx-2 text-center flex flex-column items-center justify-center "
            onClick={() => {
              navigate(`/service-shops/Pedicure`);
            }}
          >
            <div className="h-[100px] w-[100px] shadow-md rounded-full flex justify-center items-center">
              <img src={Pedicure} className="services__icon" style={{ width: '100px', height: '100px' }} />
            </div>
            <div className="mt-2 text-sm font-extrabold  bg-clip-text text-[#6B6B6B] text-center tracking-wide">
              <span>Pedicure</span>
            </div>
          </div>

          <div
            className="p-2 mx-2 text-center flex flex-column items-center justify-center "
            onClick={() => {
              navigate(`/service-shops/Hair Spa`);
            }}
          >
            <div className="h-[100px] w-[100px] shadow-md rounded-full flex justify-center items-center">
              <img src={Hairspa} className="services__icon" style={{ width: '100px', height: '100px' }} />
            </div>
            <div className="mt-2 text-sm font-extrabold  bg-clip-text text-[#6B6B6B] text-center tracking-wide">
              <span>Hair Spa</span>
            </div>
          </div>

          <div
            className="p-2 mx-2 text-center flex flex-column items-center justify-center "
            onClick={() => {
              navigate(`/service-shops/Makeup`);
            }}
          >
            <div className="h-[100px] w-[100px] shadow-md rounded-full flex justify-center items-center">
              <img src={Makeup} className="services__icon" style={{ width: '100px', height: '100px' }} />
            </div>
            <div className="mt-2 text-sm font-extrabold  bg-clip-text text-[#6B6B6B] text-center tracking-wide">
              <span>Makeup</span>
            </div>
          </div>

          <div
            className="p-2 mx-2 text-center flex flex-column items-center justify-center "
            onClick={() => {
              navigate(`/service-shops/Manicure`);
            }}
          >
            <div className="h-[100px] w-[100px] shadow-md rounded-full flex justify-center items-center">
              <img src={Manicure} className="services__icon" style={{ width: '100px', height: '100px' }} />
            </div>
            <div className="mt-2 text-sm font-extrabold  bg-clip-text text-[#6B6B6B] text-center tracking-wide">
              <span>Manicure</span>
            </div>
          </div>

          <div
            className="p-2 mx-2 text-center flex flex-column items-center justify-center "
            onClick={() => {
              navigate(`/service-shops/Straightening`);
            }}
          >
            <div className="h-[100px] w-[100px] shadow-md rounded-full flex justify-center items-center">
              <img src={Straightening} className="services__icon" style={{ width: '100px', height: '100px' }} />
            </div>
            <div className="mt-2 text-sm font-extrabold  bg-clip-text text-[#6B6B6B] text-center tracking-wide">
              <span>Straightening</span>
            </div>
          </div>

          <div
            className="p-2 mx-2 text-center flex flex-column items-center justify-center "
            onClick={() => {
              navigate(`/service-shops/Pedicure`);
            }}
          >
            <div className="h-[100px] w-[100px] shadow-md rounded-full flex justify-center items-center">
              <img src={Wax} className="services__icon" style={{ width: '100px', height: '100px' }} />
            </div>
            <div className="mt-2 text-sm font-extrabold  bg-clip-text text-[#6B6B6B] text-center tracking-wide">
              <span>Wax</span>
            </div>
          </div>
        </Carousel>
        <HorizontalScrollProgressBar progress={womenScrollProgress} />
      </div>
      <div className="container my-12">
        <h5 className="text-center font-extrabold tracking-wider my-3">
          <span className="bg-clip-text text-[#495164] mr-2 font-extrabold text-[14px] leading-[17.57px] md:text-[18px] md:leading-[21px] lg:text-[20px] lg:leading-[24px]">
            Exclusive Services For
          </span>
          <span className="text-[#1E85FF] text-[14px] leading-[17.57px] md:text-[18px] md:leading-[21px] lg:text-[20px] lg:leading-[24px]">
            Men
          </span>
        </h5>
        <Carousel
          swipeable={true}
          draggable={false}
          showDots={false}
          responsive={menServicesResponsive}
          ssr={false} // means to render carousel on server-side.
          infinite={false}
          autoPlaySpeed={1000}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
          dotListClass="custom-dot-list-style"
          itemClass=""
          afterChange={(currentSlide) => {
            handleProgressBar(currentSlide, menServicesResponsive.desktop.items, setMenScrollProgress);
          }}
        >
           {/* Men's Services Items */}
          <div
            className="p-2 mx-2 text-center flex flex-column items-center justify-center "
            onClick={() => {
              navigate(`/service-shops/Haircut`);
            }}
          >
            <div className="h-[100px] w-[100px] shadow-md rounded-full flex justify-center items-center">
              <img src={MenHaircutIcon} className="services__icon" style={{ width: '100px', height: '100px' }} />
            </div>
            <div className="mt-2 text-sm font-extrabold  bg-clip-text text-[#6B6B6B] text-center tracking-wide">
              <span>Haircut</span>
            </div>
          </div>

          <div
            className="p-2 mx-2 text-center flex flex-column items-center justify-center "
            onClick={() => {
              navigate(`/service-shops/Shaving`);
            }}
          >
            <div className="h-[100px] w-[100px] shadow-md rounded-full flex justify-center items-center">
              <img src={BeardIcon} className="services__icon" style={{ width: '100px', height: '100px' }} />
            </div>
            <div className="mt-2 text-sm font-extrabold  bg-clip-text text-[#6B6B6B] text-center tracking-wide">
              <span>Shaving</span>
            </div>
          </div>

          <div
            className="p-2 mx-2 text-center flex flex-column items-center justify-center "
            onClick={() => {
              navigate(`/service-shops/Hair Coluring`);
            }}
          >
            <div className="h-[100px] w-[100px] shadow-md rounded-full flex justify-center items-center">
              <img src={MenHairColorIcon} className="services__icon" style={{ width: '100px', height: '100px' }}  />
            </div>
            <div className="mt-2 text-sm font-extrabold  bg-clip-text text-[#6B6B6B] text-center tracking-wide">
              <span>Hair coloring</span>
            </div>
          </div>

          <div
            className="p-2 mx-2 text-center flex flex-column items-center justify-center "
            onClick={() => {
              navigate(`/service-shops/Head Massage`);
            }}
          >
            <div className="h-[100px] w-[100px] shadow-md rounded-full flex justify-center items-center">
              <img src={MassageMen} className="services__icon" style={{ width: '100px', height: '100px' }} />
            </div>
            <div className="mt-2 text-sm font-extrabold  bg-clip-text text-[#6B6B6B] text-center tracking-wide">
              <span>Head Massage</span>
            </div>
          </div>

          <div
            className="p-2 mx-2 text-center flex flex-column items-center justify-center "
            onClick={() => {
              navigate(`/service-shops/Men Facial`);
            }}
          >
            <div className="h-[100px] w-[100px] shadow-md rounded-full flex justify-center items-center">
              <img src={MenFacial} className="services__icon" style={{ width: '100px', height: '100px' }} />
            </div>
            <div className="mt-2 text-sm font-extrabold  bg-clip-text text-[#6B6B6B] text-center tracking-wide">
              <span>Facial</span>
            </div>
          </div>
        </Carousel>
        <HorizontalScrollProgressBar progress={menScrollProgress} />
      </div>
      <div>
        <div className="mt-10">
          
          <div className="relative px-2 py-6 bg-[#F2F2F2] mt-8">
          <h1 className="text-center text-sm md:text-2xl font-extrabold ">
            SALONS FOR YOU
          </h1>
            <Carousel
              swipeable={true}
              draggable={true}
              responsive={responsive}
              ssr={false}
              infinite={false}
              autoPlaySpeed={1000}
              keyBoardControl={true}
              customTransition="all .5"
              transitionDuration={500}
              containerClass="carousel-container"
              removeArrowOnDeviceType={[]}
              dotListClass="custom-dot-list-style"
              itemClass="px-4 py-5 transform transition-transform duration-300 ease-in-out"
              arrows={true}
              renderButtonGroupOutside={true}
              customLeftArrow={<PrevArrow />}
              customRightArrow={<NextArrow />}
              beforeChange={(nextSlide, { currentSlide, onMove }) => {
                handleAfterChange(nextSlide);
              }} // Updates progress bar on slide change
            >
              {shopsList.map((data, index) => {
                return (
                  <div
                    key={index}
                    className="flex flex-col bg-white shadow-md rounded-lg hover:shadow-lg transition-shadow duration-300 ease-in-out"
                    onClick={() => {
                      navigate(`/shop/${data?.shopId}`);
                    }}
                  >
                    <ShopHorizontalCard shop={data} />
                  </div>
                );
              })}
            </Carousel>
            <HorizontalScrollProgressBar progress={scrollProgress} />
            <div className="mx-auto w-full text-center mt-6">
            <span
              onClick={() => navigate(`/shops`)}
              className="text-[#2A2A2A] font-bold text-center underline cursor-pointer"
            >
              View All →
            </span>
          </div>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default Home;
