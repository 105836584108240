import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../../services/AuthService"; // Assuming this handles authentication
import LoginModal from "../Modal/LoginModal"; // Import the LoginModal component

const MenuOverlay = ({ navbarOpen, setNavbarOpen }) => {
  const navigate = useNavigate();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userDetails, setUserDetails] = useState([
    { name: "User", phone: "+910123654789" }
  ]);

  useEffect(() => {
    // Check if the user is logged in based on the presence of a token in localStorage
    const token = localStorage.getItem("token");
    setIsLoggedIn(!!token); // Convert token to boolean (true if exists, false if not)

    if (token) {
      // Fetch user details
      AuthService.getUserDetails()
        .then(response => {
          if (response.data && response.data.user) {
            setUserDetails([
              { name: response.data.user.name, phone: response.data.user.phone }
            ]);
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, []);

  const handleLogout = () => {
    AuthService.logout(); // This function clears the auth token
    setNavbarOpen(false);
    setIsLoggedIn(false); // Update the state to reflect the user is logged out
    navigate("/");
    window.location.reload(); // Reload the page to reflect the changes
  };

  const openLoginModal = () => {
    setShowLoginModal(true);
    setNavbarOpen(false);
  }

  const handleEditProfile = () => {
    setNavbarOpen(false); // Close the navbar
    navigate("/edit-profile"); // Navigate to the EditProfile page
  };

  return (
    <div
      className={`fixed inset-y-0 right-0 z-[9999] flex flex-col w-[274px] max-w-sm bg-white shadow-lg transform ${
        navbarOpen ? "translate-x-0" : "translate-x-full"
      } transition-transform duration-300 ease-in-out`}
    >
      {/* Top Navigation with Login and Close Button */}
      <div className="relative w-full h-14 bg-white shadow-sm flex justify-between items-center px-4">
        {!isLoggedIn && (
          <button
            onClick={() => openLoginModal()}
            className="bg-white border border-[#DDDDDD] rounded-full w-[73px] h-[34px] flex items-center justify-center"
          >
            <span className="text-sm font-bold text-[#2A2A2A]">Log in</span>
          </button>
        )}
        <button
          onClick={() => setNavbarOpen(false)}
          className="text-gray-700 focus:outline-none"
        >
          <svg
            className="h-6 w-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>
      </div>

      {/* Profile Section */}
      {isLoggedIn && userDetails && (
        <div className="relative flex flex-col items-center mt-10 mb-6">
          <img
            className="w-20 h-20 rounded-full"
            src={userDetails[0].profileImage || "https://via.placeholder.com/150"} // Replace with actual image URL
            alt="Profile"
          />
          <h3 className="mt-4 text-lg font-bold text-[#2A2A2A]">
            {userDetails[0].name}
          </h3>
          <p className="text-sm text-[#9D9D9D]">{userDetails[0].phone}</p>
        </div>
      )}

      {/* Edit Profile Button (only if logged in) */}
      {isLoggedIn && (
        <button
          onClick={handleEditProfile}
          className="block w-[242px] h-10 mx-auto text-center bg-[#1E85FF0D] text-blue-500 rounded-md py-2 border border-[#1E85FF33]"
        >
          Edit Your Profile
        </button>
      )}

      {/* Navigation Links */}
      <nav className="px-4 py-4 space-y-4 mt-8">
        <Link
          to="/about"
          className="block text-[16px] text-decoration-none leading-[20px] font-semibold text-[#2A2A2A]"
          onClick={() => setNavbarOpen(false)}
        >
          About
        </Link>
        {/*<Link
          to="/services"
          className="block text-[16px] leading-[20px] font-semibold text-[#2A2A2A]"
          onClick={() => setNavbarOpen(false)}
        >
          Services
        </Link>*/}
        {isLoggedIn && (
          <Link
            to="/booking-history"
            className="block text-[16px] leading-[20px] text-decoration-none font-semibold text-[#2A2A2A]"
            onClick={() => setNavbarOpen(false)}
          >
            Appointments
          </Link>
        )}
        <Link
          to="/contact"
          className="block text-[16px] leading-[20px] font-semibold text-decoration-none text-[#2A2A2A]"
          onClick={() => setNavbarOpen(false)}
        >
          Contact Us
        </Link>

        {isLoggedIn && (
          <div
            onClick={handleLogout}
            className="block text-[16px] leading-[20px] font-semibold text-[#2A2A2A] cursor-pointer"
          >
            Sign out
          </div>
        )}
      </nav>

      {/* Login Modal */}
      <LoginModal
        show={showLoginModal}
        onHide={() => setShowLoginModal(false)}
      />
    </div>
  );
};

export default MenuOverlay;