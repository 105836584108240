import './config';
import httpService from './HttpService';

const login = (data) => {
    return httpService.post("users/login", data);
};

const signup = (data) => {
    return httpService.post("users/signup", data);
};

// Check if user is logged in
const isLoggedIn = () => {
    return !!localStorage.getItem("token");
};

// Get user details
const getUserDetails = () => {
    return httpService.get("users/profile", {
        headers: {
            'authorization': "Bearer " + localStorage.getItem('token')
        }
    });
};

// Logout user
const logout = () => {
    localStorage.removeItem("token");
};

export default {
    login,
    signup,
    isLoggedIn,
    getUserDetails,
    logout
};
