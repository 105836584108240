import React, { useState, useEffect } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import the icons
import { useNavigate } from "react-router-dom"; // Import useNavigate

const OTPModal = ({ isOpen, onClose, onSubmit, mobileNumber, resendOtp }) => {
  const [otp, setOtp] = useState(Array(4).fill("")); // Creates an array with 4 empty strings

  const handleChange = (value, index) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Move focus to next input if the value is entered
    if (value.length === 1 && index < 3) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  };

  const handleSubmit = () => {
    onSubmit(otp.join("")); // Join array to create full OTP string
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center p-4">
      <div className="bg-white p-6 rounded-lg shadow-lg space-y-4">
        <h2 className="text-lg font-bold text-center">OTP Verification</h2>
        <p className="text-center">
          We have sent the verification code to your mobile number:
        </p>
        <p className="text-center text-blue-600">{mobileNumber}</p>
        <div className="grid grid-cols-4 gap-2 justify-center items-center">
          {[...Array(4)].map((_, index) => (
            <input
              key={index}
              id={`otp-input-${index}`}
              type="text"
              className="border rounded w-full text-center"
              maxLength="1"
              value={otp[index] || ""}
              onChange={(e) => handleChange(e.target.value, index)}
            />
          ))}
        </div>
        <button
          className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600"
          onClick={handleSubmit}
        >
          Verify OTP
        </button>
        <button
          className="w-full bg-transparent text-blue-500 py-2 hover:underline"
          onClick={onClose}
        >
          Close
        </button>
        <p
          className="text-center text-sm text-blue-500 cursor-pointer"
          onClick={resendOtp}
        >
          Didn't receive your code? Resend OTP
        </p>
      </div>
    </div>
  );
};

const EditProfile = () => {
  const [profileData, setProfileData] = useState({
    fullName: "Priya Ram",
    dateOfBirth: "1989-08-03",
    gender: "female",
    mobileNumber: "+910123456789",
    password: "**********",
  });

  const [passwordVisible, setPasswordVisible] = useState(false);
  const navigate = useNavigate(); // Initialize the navigate hook
  const [otpModalOpen, setOtpModalOpen] = useState(false);

  const resendOtp = () => {
    console.log("Resending OTP to", profileData.mobileNumber);
    // Implement the logic to request an OTP resend from your backend
  };

  useEffect(() => {
    // Simulate fetching data from backend
    setTimeout(() => {
      const backendData = {
        fullName: "John Doe",
        dateOfBirth: "1990-01-01",
        gender: "male",
        mobileNumber: "+910987654321",
        password: "**********",
      };
      setProfileData(backendData);
    }, 2000); // Simulated delay
  }, []);

  const handleSave = () => {
    // Trigger OTP Modal on Save
    setOtpModalOpen(true);
  };

  const closeOtpModal = () => setOtpModalOpen(false);
  const submitOtp = (otp) => {
    console.log("OTP Submitted:", otp);
    // Assume OTP is correct
    closeOtpModal();
    navigate("/"); // Navigate home or to the profile page
  };

  const handleInputChange = (field, value) => {
    setProfileData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const toggleGender = (gender) => {
    setProfileData((prevData) => ({
      ...prevData,
      gender: gender,
    }));
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible((prevVisibility) => !prevVisibility);
  };

  const handleCancel = () => {
    navigate("/"); // Navigate to the home page when Cancel is clicked
  };

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="w-full max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl m-4 p-6 bg-white shadow-lg rounded-lg">
        <h2 className="text-center text-base md:text-2xl font-bold mb-6">
          Edit Your Profile
        </h2>

        {/* Profile Image Upload */}
        <div className="text-center mb-6">
          <div className="relative w-24 h-24 mx-auto rounded-full overflow-hidden border">
            <img
              src="https://via.placeholder.com/150"
              alt="Profile"
              className="w-full h-full object-cover"
            />
            <input
              type="file"
              onChange={() => {}}
              className="absolute inset-0 opacity-0 cursor-pointer"
            />
          </div>
        </div>

        {/* Full Name */}
        <div className="mb-4">
          <label className="block text-sm text-black font-bold mb-2">
            Full Name
          </label>
          <input
            type="text"
            value={profileData.fullName}
            onChange={(e) => handleInputChange("fullName", e.target.value)}
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* Date of Birth */}
        <div className="mb-4">
          <label className="block text-sm text-black font-bold mb-2">
            Date of Birth
          </label>
          <input
            type="date"
            value={profileData.dateOfBirth}
            onChange={(e) => handleInputChange("dateOfBirth", e.target.value)}
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* Gender */}
        <div className="mb-4">
          <label className="block text-sm font-bold mb-2">Gender</label>
          <div className="flex space-x-4">
            <button
              onClick={() => toggleGender("male")}
              className={`flex-1 py-2 border rounded-md ${
                profileData.gender === "male"
                  ? "bg-[#1E85FF]/5 text-[#1E85FF]"
                  : "bg-[#FFFFFF] text-[#9D9D9D]"
              }`}
            >
              Male
            </button>
            <button
              onClick={() => toggleGender("female")}
              className={`flex-1 py-2 border rounded-md ${
                profileData.gender === "female"
                  ? "bg-[#1E85FF]/5 text-[#1E85FF]"
                  : "bg-[#FFFFFF] text-[#9D9D9D]"
              }`}
            >
              Female
            </button>
          </div>
        </div>

        {/* Mobile Number */}
        <div className="mb-4">
          <label className="block text-sm font-bold mb-2">Mobile Number</label>
          <input
            type="tel"
            value={profileData.mobileNumber}
            onChange={(e) => handleInputChange("mobileNumber", e.target.value)}
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* Password */}
        <div className="mb-4 relative">
          <label className="block text-sm font-bold mb-2">Password</label>
          <div className="relative">
            <input
              type={passwordVisible ? "text" : "password"}
              value={profileData.password}
              onChange={(e) => handleInputChange("password", e.target.value)}
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="absolute inset-y-0 right-3 flex items-center text-sm leading-5 focus:outline-none"
            >
              {passwordVisible ? (
                <FaEyeSlash className="h-5 w-5 text-gray-500" />
              ) : (
                <FaEye className="h-5 w-5 text-gray-500" />
              )}
            </button>
          </div>
        </div>

        {/* Buttons */}
        <div className="flex justify-between mt-6">
          <button
            onClick={() => navigate("/")}
            className="py-2 px-4 bg-gray-300 rounded-md hover:bg-gray-400"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            className="py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600"
          >
            Save
          </button>
        </div>
      </div>
      <OTPModal
        isOpen={otpModalOpen}
        onClose={closeOtpModal}
        onSubmit={submitOtp}
        mobileNumber={profileData.mobileNumber}
        resendOtp={resendOtp}
      />
    </div>
  );
};

export default EditProfile;
