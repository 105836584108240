import React from "react";

const HeaderView = ({ navbarOpen, setNavbarOpen }) => {
  return (
    <header className="w-full top-0 left-0 p-3 flex items-center justify-between z-20">
      {/* Logo */}
      <div className="text-white flex-grow z-20">
        <h1 className="text-2xl font-bold my-0">Logo</h1>
      </div>
      {/* Hamburger Icon */}
      <button
      className="lg:hidden flex items-center justify-center p-2  rounded-md focus:outline-none"
      onClick={() => setNavbarOpen(!navbarOpen)}
    >
      <div className="relative w-6 flex flex-col justify-between items-center">
        <span
          className={`absolute block h-0.5 w-full bg-gray-800 transform transition duration-300 ease-in-out ${
            navbarOpen ? "rotate-45 translate-y-2" : "-translate-y-1.5"
          }`}
        ></span>
        <span
          className={`absolute block h-0.5 w-full bg-gray-800 transform transition duration-300 ease-in-out ${
            navbarOpen ? "opacity-0" : "opacity-100"
          }`}
        ></span>
        <span
          className={`absolute block h-0.5 w-full bg-gray-800 transform transition duration-300 ease-in-out ${
            navbarOpen ? "-rotate-45 -translate-y-2" : "translate-y-1.5"
          }`}
        ></span>
      </div>
    </button>
    </header>
  );
};

export default HeaderView;
